@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 100 900;
  src: local("Inter"),
    url("../../assets/fonts/Inter/inter-var-latin.woff2") format("woff2");
}

@font-face {
  font-family: "GT Walsheim Regular";
  src: url("../../assets/fonts/GT-Walsheim/GT-Walsheim-Regular.woff2")
      format("woff2"),
    url("../../assets/fonts/GT-Walsheim/GT-Walsheim-Regular.woff")
      format("woff");
  font-style: normal;
}

@font-face {
  font-family: "GT Walsheim Medium";
  src: url("../../assets/fonts/GT-Walsheim/GT-Walsheim-Pro-Medium.woff2")
      format("woff2"),
    url("../../assets/fonts/GT-Walsheim/GT-Walsheim-Pro-Medium.woff")
      format("woff");
  font-style: normal;
}

@font-face {
  font-family: "GT Walsheim Bold";
  src: url("../../assets/fonts/GT-Walsheim/GT-Walsheim-Pro-Bold.woff2")
      format("woff2"),
    url("../../assets/fonts/GT-Walsheim/GT-Walsheim-Pro-Bold.woff")
      format("woff");
  font-feature-settings: "kern", "liga", "tnum", "ss01";
  font-style: normal;
}
