.App {
  text-align: center;
  width: 100vw;
}

.App h2 {
  font-weight: 400;
}

.redoc-wrap {
  width: 100vw;
}

.menu-content {
  border-right: 1px solid #e2e8f0;
}

.menu-content > div:first-child {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 34px 16px;
}

.menu-content > div:first-child > img {
  width: 100px;
  margin: 8px;
  padding: 0;
}

body {
  font-size: 16px;
}

body .api-info {
  padding-top: 20px;
}

body .api-info a {
  display: inline-flex;
  appearance: none;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  transition: all 250ms ease 0s;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline: none;
  width: auto;
  line-height: 1.2;
  border-radius: 8px;
  border: 1px solid #e5e7eb;
  font-weight: 600;
  font-family: "GT Walsheim Medium", sans-serif;
  padding-top: 1.1rem;
  padding-bottom: 1.1rem;
  border-width: 1px;
  height: 2.5rem;
  min-width: 2.5rem;
  padding-inline-start: 1rem;
  padding-inline-end: 1rem;
  background: #6f3bfe;
  color: #ffffff;
  margin-left: 15px;
}

body .api-info a:active {
  background: #140a23;
}

body label[role="menuitem"] {
  font-size: 16px;
  padding-left: 24px;
}

body div[role="search"] {
  margin-bottom: 10px;
}

body div[role="search"] > i {
  right: 26px;
  line-height: 2.4em;
}

body .search-icon {
  height: 2.3em;
  left: 31px;
}

body .search-input {
  background-color: #f7fafc;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  border: none;
  font-size: 14px;
  min-height: 28px;
  padding: 10px 8px 10px 32px;
}

body .search-input::placeholder {
  font-weight: 500;
  color: #333333;
}

body div[data-section-id] {
  padding: 30px 0;
}

body div[data-section-id]:not(:last-of-type):after {
  border-bottom: 1px solid #e2e8f0;
}
